<template>
  <div>
    <!-- <v-progress-linear class="mt-2" indeterminate v-if="loading" color="primary darken-2"></v-progress-linear> -->
    <data-list :payload="payload" :module="MODULE" :model="modelObj" :key="reInit" moduleTitle="message.projects.project" propId="project_id"></data-list>
    <v-dialog v-model="dialog" hide-overlay transition="dialog-bottom-transition" persistent fullscreen>
      <v-card class="pa-0 ma-0">
        <v-app-bar color="primary" dense dark fixed>
          <v-toolbar-title>{{ $t(`message.checklist.${checklistId ? 'updateChecklist' : 'addCheckList' }`) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab small dark @click="closeDialogHandler">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text class="pa-0 ma-0" style="position:absolute; top:50px">
          <form-render :formId="formId" :module="MODULE" v-if="dialog" propId="project_id" :checklistId="checklistId" :formName="formName" :checklistName="checklistTitle" :selectedItemId="selectedItemId" :projectChecklistFormData="projectChecklistFormData" :checklistEmail="checklistEmail" :canSendEmail="canSendEmail" :dataGridImportValue="dataGridImportValue" :checklistData="checklistData" :checklistTemplateData="checklistTemplateData" :moduleType="moduleType" :moduleID="moduleID"></form-render>
          </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      modelObj: {},
      reInit: 0,
      payload: {},
      dialog: false,
      formId: '',
      formName: '',
      checklistId: '',
      loading: false,
      selectedItemId: 0,
      checklistTitle: '',
      MODULE: 'project',
      projectChecklistFormData: null,
      checklistEmail: '',
      canSendEmail: '',
      dataGridImportValue: null,
      checklistData: [],
      checklistTemplateData: [],
      moduleID: null,
      moduleType: null
    }
  },
  components: {
    'data-list': () => import('@/components/checklist/List'),
    'form-render': () => import('@/components/checklist/Actions')
  },
  mounted () {
    this.$eventBus.$on('checklistDialog', (data) => {
      this.formId = data.form_id
      this.checklistId = null
      this.formName = data.form_name
      this.selectedItemId = data.itemId
      this.dialog = true
      this.projectChecklistFormData = data.projectChecklistFormData
      this.checklistTitle = ''
      this.checklistEmail = data.checklist_recipients
      this.canSendEmail = data.can_send_email
      this.dataGridImportValue = data.dataGridImportValue
      this.checklistData = data.checklist_data
      this.checklistTemplateData = data.getForm
      this.moduleID = data.moduleId
      this.moduleType = data.moduleType
    })
    this.$eventBus.$on('updatechecklistDialog', (data) => {
      this.dialog = true
      this.checklistId = data.checklist_id || null
      this.formId = data.form_id
      this.formName = data.form_name
      this.selectedItemId = data.itemId
      this.checklistTitle = data.title
      this.checklistEmail = data.checklist_recipients
      this.canSendEmail = data.can_send_email
      this.checklistData = data.checklist_data
    })
    this.$eventBus.$on('closeChecklistDialog', () => {
      this.dialog = false
      this.$eventBus.$emit('clearChecklist')
    })
    this.$eventBus.$on('setChecklistId', (checklistId) => {
      this.checklistId = checklistId
    })
    this.$eventBus.$on('showLoading', () => {
      this.loading = true
    })
    this.$eventBus.$on('hideLoading', () => {
      this.loading = false
    })
  },
  methods: {
    closeDialogHandler () {
      this.dialog = false
      this.$eventBus.$emit('clearChecklist')
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('checklistDialog')
    this.$eventBus.$off('updatechecklistDialog')
    this.$eventBus.$off('closeChecklistDialog')
    this.$eventBus.$off('setChecklistId')
    this.$eventBus.$off('hideLoading')
    this.$eventBus.$off('showLoading')
  }
}
</script>

<style>

</style>
